import React, { useState, useMemo, useEffect } from "react";
import queries from "../helpers/queries";
import { withUser } from "../Contexts/user";
import Table from "../Components/salesRankingTable";
import { useQuery } from "@apollo/react-hooks";
const moment = require("moment");

const dateTo = moment(Date.now()).subtract(1,"day").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
const dateFrom = moment(Date.now()).subtract(1, 'day').format("YYYY-MM-DDTHH:mm:ss.SSSZ")

const NumberFormatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const RegionSalesRankingPercentage = (user) => {
    let stateName = ""
    if (user.user.auth.state.name === "Kuala Lumpur") {
        stateName = "kl"
    } else if (user.user.auth.state.name === "Johor Bahru") {
        stateName = "jb"
    } else if (user.user.auth.state.name === "Melaka") {
        stateName = "melaka"
    } else if (user.user.auth.state.name === "Ipoh") {
        stateName = "ipoh"
    } 
    // eslint-disable-next-line
    const [countdownDate, setCountdownDate] = useState(
        new Date("12/31/2025").getTime()
    );
    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const [top10Data, setTop10Data] = useState([]);

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });



    useEffect(() => {
        const setNewTime = () => {
            if (countdownDate) {
                const currentTime = new Date().getTime();
    
                const distanceToDate = countdownDate - currentTime;
    
                let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
                let hours = Math.floor(
                    (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                let minutes = Math.floor(
                    (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
                );
                let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
    
                const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    
                days = `${days}`;
                if (numbersToAddZeroTo.includes(hours)) {
                    hours = `0${hours}`;
                } else if (numbersToAddZeroTo.includes(minutes)) {
                    minutes = `0${minutes}`;
                } else if (numbersToAddZeroTo.includes(seconds)) {
                    seconds = `0${seconds}`;
                }
    
                setState({ days: days, hours: hours, minutes, seconds });
            }
        };

        setInterval(() => setNewTime(), 1000);
    }, [countdownDate]);


    const { data: casesData } = useQuery(
        queries.FETCH_TOP_30,
        {
            fetchPolicy: "network-only",
            variables: {
                dateTo: dateTo,
                dateFrom: dateFrom,                             
                state: stateName,
                type: "sales"
            },
            onCompleted: () => {
                const originalData =
                    casesData.fetchTop30s.data.top30;

                setTop10Data(originalData);
            },
        }
    );

    const capitalizeWords = str => {
        return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
      };

    const columns = useMemo(
        () => [
            {
                Header: "Agent",
                accessor: "member",
                Cell: ({ value, row: { original } }) => {
                    if (
                        original.position === 1 ||
                        original.position === 2 ||
                        original.position === 3
                    ) {
                        return (
                            <div className="flex items-center justify-center">
                                <img
                                    className="w-12 h-12"
                                    src={
                                        original.position === 1
                                                                                       ? ("https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_RankingIcon_7ABwkIkWd.png?updatedAt=1719489373979")
                                            : original.position === 2
                                            ? ("https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_RankingIcon2_He0-VHBn9.png?updatedAt=1719489374245")
                                            : original.position === 3
                                            ? ("https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_RankingIcon3_IbgsJuqWo.png?updatedAt=1719489374210")
                                            : ""
                                    }
                                    alt=""
                                />
                                <div className="capitalize min-width-150">
                                                                {value.nickname === "-" ? `${capitalizeWords(value.name)}` : `${capitalizeWords(value.nickname)} ${capitalizeWords(value.name)}`}
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="flex items-center justify-center h-12">
                                <div className="mx-4 text-lg">
                                    #{original.position}
                                </div>
                                <div className="capitalize min-width-150">
                                                            {value.nickname === "-" ? `${capitalizeWords(value.name)}` : `${capitalizeWords(value.nickname)} ${capitalizeWords(value.name)}`}
                                </div>
                            </div>
                        );
                    }
                },
            },
            {
                Header: "Commission",
                accessor: "commission",
                Cell: ({ value, row: { original } }) => {
                    if (original.status === "down") {
                        return (
                            <div className="flex items-center justify-center">
                                <div className="min-width-80">
                                    {NumberFormatter.format(value)}%
                                </div>
                            </div>
                        );
                    } else if (original.status === "up") {
                        return (
                            <div className="flex items-center justify-center">
                                <div className="min-width-80">
                                    {NumberFormatter.format(value)}%
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="flex items-center justify-center">
                                <div className="min-width-80">
                                    {NumberFormatter.format(value)}%
                                </div>
                            </div>
                        );
                    }
                },
            },
        ],
        []
    );

    return (
        <div>
            {process.env.REACT_APP_ORGANISATION === "DND" && (
                <div className="countdown-wrapper">
                    <div className="time-section">
                        <div className="time">{state.days || "0"}</div>
                        <small className="time-text">Days</small>
                    </div>
                    <div className="time-section">
                        <div className="time">:</div>
                    </div>
                    <div className="time-section">
                        <div className="time">{state.hours || "00"}</div>
                        <small className="time-text">Hours</small>
                    </div>
                    <div className="time-section">
                        <div className="time">:</div>
                    </div>
                    <div className="time-section">
                        <div className="time">{state.minutes || "00"}</div>
                        <small className="time-text">Minutes</small>
                    </div>
                    <div className="time-section">
                        <div className="time">:</div>
                    </div>
                    <div className="time-section">
                        <div className="time">{state.seconds || "00"}</div>
                        <small className="time-text">Seconds</small>
                    </div>
                </div>
            )}
                        <div
    style={{
        backgroundImage: `url("/bg1.jpg")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    }}
    className="font-serif"
>
    <div className="py-8 min-h-screen px-2 lg:px-12 flex justify-center">
        <Table
            columns={columns}
            data={top10Data}
            updatePagination={setPaginationState}
            paginationState={paginationState}
            hideHeader={true}
            hidePagination={true}
            pageCount={
                0
            }
            totalCount={
                0
            }
        />
    </div>
</div>
        </div>
    );
};

export default withUser(RegionSalesRankingPercentage);
